import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";

import { FaList, FaPoundSign, FaRegClock, FaSearch } from "react-icons/fa";

import LogoImage from "../../assets/images/color-background-web.svg";

import Card from "../../components/UI/card/card.component";
import {
  BackButtonContainer,
  GreenHeader,
  GreenP,
  TextDivider,
  VerticalAreaFullWidth,
} from "../../global.styles";
import { RootState } from "../../redux/store";
import Spinner from "../../components/UI/spinner/spinner.component";
import InnerCard from "../../components/UI/inner-card/inner-card.component";
import Button from "../../components/UI/button/button.component";
import ErrorMessage from "../../components/UI/error-message/error-message.component";
import QuoteForm from "../../components/bookings/quote-form.component";
import {
  BookingType,
  isBookingType,
  QuoteRequestType,
} from "../../redux/bookings/bookings.types";
import { firestore } from "../../firebase/config";

type HomeProps = {
  quoteMigrationComplete: boolean;
};

const Home: FC<HomeProps> = ({quoteMigrationComplete}) => {
  const navigate = useNavigate();

  const { userLoading, currentUser, userError } = useSelector(
    (state: RootState) => state.user
  );

  const [recentQuote, setRecentQuote] = useState<BookingType | null>(null);
  const [quoteRequest, setQuoteRequest] = useState<QuoteRequestType | null>(
    null
  );

  useEffect(() => {
    if (currentUser && !currentUser.name) {
      navigate("/profile");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    //fetch users recent quote
    const fetchRecentQuote = async () => {
      if (!currentUser || !currentUser.id || !quoteMigrationComplete) return;

      const quoteRef = doc(
        firestore,
        "users",
        currentUser.id,
        "quotes",
        "recentQuote"
      );
      const quoteSnap = await getDoc(quoteRef);

      if (
        quoteSnap.exists() &&
        quoteSnap.data() &&
        isBookingType(quoteSnap.data())
      ) {
        setRecentQuote(quoteSnap.data() as BookingType);
        setQuoteRequest({
          userId: currentUser.id,
          date: quoteSnap.data().searchDate,
          time: quoteSnap.data().time,
          pickupAddress: quoteSnap.data().pickupAddress,
          destinationAddresses: quoteSnap.data().destinationAddresses,
          vehicleType: quoteSnap.data().vehicleType,
          hasPet: quoteSnap.data().hasPet,
          meetAndGreet: quoteSnap.data().meetAndGreet,
        } as QuoteRequestType);
      } else {
        setRecentQuote(null); // No quote found
      }
    };

    fetchRecentQuote();
  }, [currentUser, quoteMigrationComplete]);

  return (
    <Card>
      {userLoading && <Spinner />}
      {userError && <ErrorMessage slice="user">{userError}</ErrorMessage>}

      {!userLoading && !currentUser && (
        <>
          <VerticalAreaFullWidth>
            <img src={LogoImage} width="19%" alt="Rufus" />
          </VerticalAreaFullWidth>
          <TextDivider>
            <GreenP>BRISTOL</GreenP>
          </TextDivider>
          <QuoteForm isRouteComponent={false} />
        </>
      )}

      {!userLoading && currentUser && currentUser.name && (
        <>
          <GreenHeader>Welcome, {currentUser.name}!</GreenHeader>
          <BackButtonContainer>
            <Button onClick={() => navigate("/bookings/quote-form")}>
              Book a New Trip!
            </Button>
          </BackButtonContainer>
          {recentQuote && (
            <InnerCard
              onClick={() =>
                navigate("/bookings/booking-form", {
                  state: { quoteRequest: quoteRequest, quote: recentQuote },
                })
              }
            >
              <FaPoundSign size={32} />
              <h3>My Recent Quote</h3>
            </InnerCard>
          )}
          <TextDivider>
            <GreenP> MY TRIPS </GreenP>
          </TextDivider>
          <InnerCard onClick={() => navigate("/bookings")}>
            <FaRegClock size={32} />
            <h3>Active Trips</h3>
          </InnerCard>
          <InnerCard onClick={() => navigate("/bookings/history")}>
            <FaList size={32} />
            <h3>Trips History</h3>
          </InnerCard>
          <InnerCard onClick={() => navigate("/bookings/search")}>
            <FaSearch size={32} />
            <h3>Search Trips</h3>
          </InnerCard>
        </>
      )}
    </Card>
  );
};

export default Home;
