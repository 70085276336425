import { styled } from "styled-components";

import { colors } from "./style-variables";

export const ButtonContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonColumnContainer = styled.div`
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  h3 {
    color: ${colors.dark};
    border-bottom: 2px solid ${colors.dark};
  }
  a {
    text-decoration: none;
  }
`;

export const FullWidth = styled.div`
  width: 100%;
`;

export const SpreadApartArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
`;

export const SpaceAroundArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;
`;

export const PushToRightArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: right;
`;

export const PushToLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const PushToRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const PushToLefttArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: left;
`;

export const VerticalArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const VerticalAreaFullWidth = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const FlexGrowItem = styled.div`
  flex-grow: 1;
`;

export const ClickableArea = styled.div`
  cursor: pointer;
`;

export const SpreadButtonContainer = styled(BackButtonContainer)`
  justify-content: space-between;
`;

export const GreenHeader = styled.h2`
  padding: 1rem;
  color: ${colors.light};
`;
export const AmberHeader = styled.h2`
  padding: 1rem;
  color: ${colors.primary};
`;
export const RedHeader = styled.h2`
  padding: 1rem;
  color: ${colors.warning};
`;

export const GreenP = styled.p`
  color: ${colors.light};
`;
export const RedP = styled.p`
  color: ${colors.warning};
  font-weight: bold;
  margin: 1rem;
`;
export const AmberP = styled.p`
  color: ${colors.primary};
`;
export const GreenX = styled(GreenP)`
  width: 3rem;
  font-weight: bold;
`;

export const SpeechBubble = styled.p`
  // layout
  position: relative;
  max-width: 30rem;
  // looks
  background-color: ${colors.light};
  color: ${colors.lightTextHigh};
  padding: 1.125rem 1.5rem;
  font-size: 1.25rem;
  border-radius: 1rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
    0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);

  &::before {
    // layout
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 2rem; // offset should move with padding of parent
    border: 0.75rem solid transparent;
    border-top: none;
    // looks
    border-bottom-color: ${colors.light};
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
  }
`;

export const TextDivider = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center; // align text and lines vertically
  --text-divider-gap: 1rem; // set a customizable gap between label and lines
  &::before,
  &::after {
    content: "";
    height: 1px;
    background-color: ${colors.dark};
    flex-grow: 1; // both lines will expand to occupy the available space
  }
  &::before {
    margin-right: var(--text-divider-gap);
  }
  &::after {
    margin-left: var(--text-divider-gap);
  }
`;
