import { ChangeEvent, FC, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  useJsApiLoader,
  Libraries,
  StandaloneSearchBoxProps,
  // AutocompleteProps,
} from "@react-google-maps/api";
import { v4 as uuidv4 } from "uuid";

import {
  BackButtonContainer,
  ClickableArea,
  FlexGrowItem,
  GreenHeader,
  GreenP,
  GreenX,
  PushToRightArea,
  RedHeader,
  SpaceAroundArea,
  SpreadApartArea,
} from "../../global.styles";
import Card from "../UI/card/card.component";
import FormInput from "../UI/form-input/form-input.component";
import { GreenButton } from "../UI/button/button.styles";
import Button from "../UI/button/button.component";
import { SpacerHorizontal } from "../UI/spacers/spacers.component";
import RadioButton from "../UI/radio-buttons/radio-button";
import {
  VehcileTypeArray,
  VehicleType,
} from "../../redux/vehicles/vehicles.types";
import { getPriceQuote } from "../../firebase/bookings";
import Spinner from "../UI/spinner/spinner.component";
import Checkbox from "../UI/checkbox/checkbox.component";
import {
  AddressType,
  isAddressType,
} from "../../redux/bookings/bookings.types";

const libraries: Libraries = ["places"]; // Move this outside the component
const searchBoxOptions: StandaloneSearchBoxProps = {
  bounds: {
    north: 51.5546,
    south: 51.3453,
    east: -2.3609,
    west: -2.8725,
  },
  // types: ["geocode"], // Prioritize address searches
  // componentRestrictions: { country: "gb" }, // Limit results to Great Britain
};

type QuoteFormProps = {
  isRouteComponent: boolean;
};

const QuoteForm: FC<QuoteFormProps> = ({ isRouteComponent }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Places API Autocomplete functionality
  const pickupRef = useRef<google.maps.places.Autocomplete | null>(null);
  const destinationRefs = useRef<(google.maps.places.Autocomplete | null)[]>(
    []
  );
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_PLACES_API_KEY!,
    libraries,
  });

  const initialQuoteRequest = location.state
    ? location.state.quoteRequest
    : null;
  const initialDate = initialQuoteRequest
    ? (initialQuoteRequest.date as string)
    : "";
  const initialTime = initialQuoteRequest
    ? (initialQuoteRequest.time as string)
    : "";
  const initialPickupAddress = initialQuoteRequest
    ? (initialQuoteRequest.pickupAddress as AddressType)
    : { userEntry: "", googleAddress: "" };
  const initialPickup = initialQuoteRequest
    ? (initialQuoteRequest.pickupAddress.userEntry as string)
    : "";
  const initialHasPet = initialQuoteRequest
    ? (initialQuoteRequest.hasPet as boolean)
    : false;
  const initialMeetAndGreet = initialQuoteRequest
    ? (initialQuoteRequest.meetAndGreet as boolean)
    : false;
  const initialDestinationAdresses = initialQuoteRequest
    ? (initialQuoteRequest.destinationAddresses as AddressType[])
    : [];
  const initialDestinations = initialQuoteRequest
    ? (initialQuoteRequest.destinationAddresses.map(
        (address: AddressType) => address.userEntry as string
      ) as string[])
    : [""];
  const initialVehicleType = initialQuoteRequest
    ? initialQuoteRequest.vehicleType
    : "4Seater";
  //states for quote response
  const [loadingQuote, setLoadingQuote] = useState(false);
  const [quoteError, setQuoteError] = useState("");
  //form states
  const [date, setDate] = useState(initialDate);
  const [time, setTime] = useState(initialTime);
  const [pickup, setPickup] = useState(initialPickup);
  const [pickupAddress, setPickupAddress] =
    useState<AddressType>(initialPickupAddress);
  //variable amount of destinations (max 10)
  const [destinations, setDestinations] = useState(initialDestinations);
  const [destinationAddresses, setDestinationAddresses] = useState<
    AddressType[]
  >(initialDestinationAdresses);
  //vehicle type
  const [vehicleType, setVehicleType] =
    useState<VehicleType>(initialVehicleType);
  //does Passender have a pet
  const [hasPet, setHasPet] = useState(initialHasPet);
  //meet and greet
  const [meetAndGreet, setMeetAndGreet] = useState(initialMeetAndGreet);

  //form input handlers
  const dateChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };
  const timeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setTime(event.target.value);
  };
  const pickupChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPickup(event.target.value);
  };
  const destinationChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    //things we do to keep TypeScript happy :)
    const passedIndex = event.target.dataset.index;
    if (passedIndex && typeof Number(passedIndex) === "number") {
      const updatedDestinations = [...destinations];
      updatedDestinations[Number(passedIndex)] = event.target.value;
      setDestinations(updatedDestinations);
    }
  };
  const addExtraDestination = () => {
    const updatedDestinations = [...destinations, ""];
    setDestinations(updatedDestinations);
  };
  const removeExtraDestination = (index: number) => {
    // const updatedDestinations = destinations.filter((_, i) => i !== index); //OR
    // const updatedDestinations = destinations.toSpliced(index, 1); //TypeScript or ES versions too old
    const updatedDestinations = [...destinations];
    updatedDestinations.splice(index, 1);
    setDestinations(updatedDestinations);
    // Remove the corresponding ref
    destinationRefs.current.splice(index, 1);
    //also update destination addresses by removing relevant element
    const updatedDestinationAddresses = [...destinationAddresses];
    updatedDestinationAddresses.splice(index, 1);
    setDestinationAddresses(updatedDestinationAddresses);
    // Remove the corresponding ref
    destinationRefs.current.splice(index, 1);
  };
  const vehicleTypeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (VehcileTypeArray.includes(event.target.value as VehicleType)) {
      setVehicleType(event.target.value as VehicleType);
    }
  };
  const hasPetHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setHasPet(!hasPet);
  };
  const meetAndGreetHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setMeetAndGreet(!meetAndGreet);
  };
  // Handle pickup selection
  const handlePickupSelected = () => {
    if (pickupRef.current) {
      const place = pickupRef.current.getPlace();
      if (place && place.name && place.formatted_address) {
        setPickup(place.name || ""); // Update pickup state with selected address
        setPickupAddress({
          userEntry: place.name || "",
          googleAddress: place.formatted_address || "",
        });
      }
    }
  };
  // Handle destination selection
  const handleDestinationSelected = (index: number) => {
    if (destinationRefs.current[index]) {
      const place = destinationRefs.current[index]!.getPlace();
      if (place && place.name && place.formatted_address) {
        // Update the corresponding destination in the state
        const updatedDestinations = [...destinations];
        updatedDestinations[index] = place.name || "";
        setDestinations(updatedDestinations);
        const updatedDestinationAddresses = [...destinationAddresses];
        updatedDestinationAddresses[index] = {
          userEntry: place.name || "",
          googleAddress: place.formatted_address || "",
        };
        setDestinationAddresses(updatedDestinationAddresses);
      }
    }
  };

  //submit
  const submitHandler = async () => {
    if (!date || date === "" || !time || time === "") {
      setQuoteError("Please provide date and time");
      return;
    }
    if (new Date(`${date} ${time}`).getTime() < Date.now()) {
      setQuoteError(
        "Can't book a trip for past time! Time travel is not invented yet :)"
      );
      return;
    }
    if (!pickupAddress || !isAddressType(pickupAddress)) {
      setQuoteError("Please select a valid pickup address");
      return;
    }
    if (destinations.some((destination) => destination === "")) {
      setQuoteError("Please provide all destination addresses");
      return;
    }
    if (destinationAddresses.some((address) => !isAddressType(address))) {
      setQuoteError("Please select valid destination addresses");
      return;
    }
    if (!isRouteComponent) {
      const tempQuoteId = localStorage.getItem("tempQuoteId") || uuidv4(); // Keep the same ID
      localStorage.setItem("tempQuoteId", tempQuoteId); // Ensure it's saved in case it's new
    }
    setLoadingQuote(true);
    const quoteRequest = {
      date,
      time,
      pickupAddress,
      destinationAddresses,
      vehicleType,
      hasPet,
      meetAndGreet,
      tempQuoteId: localStorage.getItem("tempQuoteId") || "",
    };
    try {
      const quote = await getPriceQuote(quoteRequest);
      setLoadingQuote(false);
      setQuoteError("");
      if (isRouteComponent) {
        navigate("/bookings/booking-form", { state: { quoteRequest, quote } });
      } else {
        navigate("/public-booking-form", { state: { quoteRequest, quote } });
      }
    } catch (error) {
      if (error instanceof Error) {
        setQuoteError(error.message);
      } else {
        setQuoteError("Error processing the quote. Please, try again later");
      }
      setLoadingQuote(false);
    }
  };

  //render differs depending where QuoteForm is located
  //we wrap it either with Card as route compnent or empty tags if it's embeded
  const content = (
    <div>
      {isRouteComponent && (
        <>
          <BackButtonContainer>
            <Link to="/">
              <h3>Go Back</h3>
            </Link>
          </BackButtonContainer>
        </>
      )}
      <GreenHeader>Book Your Trip:</GreenHeader>
      {quoteError && <RedHeader>{quoteError}</RedHeader>}
      {isLoaded ? (
        <Autocomplete
          onLoad={(ref) => (pickupRef.current = ref)}
          onPlaceChanged={handlePickupSelected}
          options={searchBoxOptions}
        >
          <FormInput
            label="Pickup address"
            id="pickup_address"
            type="text"
            placeholder=""
            onChange={pickupChangeHandler}
            value={pickup}
            required
          />
        </Autocomplete>
      ) : (
        <Spinner />
      )}

      {destinations.map((destination, index) => {
        return (
          <SpreadApartArea key={`destination-${index}`}>
            <FlexGrowItem>
              {isLoaded && (
                <Autocomplete
                  onLoad={(ref) => (destinationRefs.current[index] = ref)}
                  onPlaceChanged={() => handleDestinationSelected(index)}
                  options={searchBoxOptions}
                >
                  <FormInput
                    label={`Destination address ${
                      destinations.length > 1 ? (index + 1).toString() : ""
                    }`}
                    id={`destination-address${destination.length > 1 && index}`}
                    type="text"
                    placeholder=""
                    data-index={index}
                    value={destinations[index]}
                    onChange={destinationChangeHandler}
                    required
                  />
                </Autocomplete>
              )}
            </FlexGrowItem>
            {destinations.length > 1 && (
              <ClickableArea onClick={removeExtraDestination.bind(null, index)}>
                <GreenX>X</GreenX>
              </ClickableArea>
            )}
          </SpreadApartArea>
        );
      })}
      {destinations.length < 10 && (
        <PushToRightArea>
          <GreenP>Add Extra Destination</GreenP>
          <SpacerHorizontal $paddingRem={2} />
          <GreenButton type="button" onClick={addExtraDestination}>
            Add
          </GreenButton>
        </PushToRightArea>
      )}
      <SpaceAroundArea>
        <GreenP>Date and time:</GreenP>
        <FormInput
          label=""
          id="date"
          type="date"
          onChange={dateChangeHandler}
          value={date}
          required
        />
        <FormInput
          label=""
          id="time"
          type="time"
          onChange={timeChangeHandler}
          value={time}
          required
        />
      </SpaceAroundArea>
      <GreenP>Please select vehicle type:</GreenP>
      <RadioButton
        label="4 Seater"
        id="4Seater"
        name="vehicleType"
        value="4Seater"
        defaultChecked={vehicleType === "4Seater"}
        // checked={vehicleType === "4Seater"}
        onChange={vehicleTypeHandler}
      />
      <RadioButton
        label="4 Seater Estate"
        id="4SeaterEstate"
        name="vehicleType"
        value="4SeaterEstate"
        defaultChecked={vehicleType === "4SeaterEstate"}
        onChange={vehicleTypeHandler}
      />
      <RadioButton
        label="6 Seater"
        id="6Seater"
        name="vehicleType"
        value="6Seater"
        defaultChecked={vehicleType === "6Seater"}
        onChange={vehicleTypeHandler}
      />
      <RadioButton
        label="Minibus (7 or 8 seats)"
        id="minibus"
        name="vehicleType"
        value="minibus"
        defaultChecked={vehicleType === "minibus"}
        onChange={vehicleTypeHandler}
      />
      <RadioButton
        label="Executive"
        id="executive"
        name="vehicleType"
        value="executive"
        defaultChecked={vehicleType === "executive"}
        onChange={vehicleTypeHandler}
      />
      <GreenP>Some of our Drivers will happily take Your Pet onboard:</GreenP>
      <Checkbox
        label="Include a Pet (£8 extra)"
        id="pet"
        name="pet"
        checked={hasPet}
        onChange={hasPetHandler}
      />
      <GreenP>
        Our Driver can meet you in the arrivals hall with Your name on a board
        (applies to airport pick-ups):
      </GreenP>
      <Checkbox
        label="Meet & Greet (£10 extra)"
        id="meetAndGreet"
        name="meetAndGreet"
        checked={meetAndGreet}
        onChange={meetAndGreetHandler}
      />
      {quoteError && <RedHeader>{quoteError}</RedHeader>}
      <BackButtonContainer>
        <Button onClick={submitHandler}>Get a Quote</Button>
      </BackButtonContainer>
    </div>
  );

  if (loadingQuote) return <Spinner />;

  return isRouteComponent ? <Card>{content}</Card> : <>{content}</>;
};

export default QuoteForm;
