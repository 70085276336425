import { Link } from "react-router-dom";
import Card from "../../components/UI/card/card.component";
import { BackButtonContainer, GreenHeader, GreenP } from "../../global.styles";

const Terms = () => {
  return (
    <Card>
      <GreenHeader>Terms & Conditions</GreenHeader>
      <BackButtonContainer>
        <GreenP>
          Effective Date: <strong>25th of May 2024</strong>
        </GreenP>
      </BackButtonContainer>
      <h3>1. Introduction</h3>
      <p>
        Welcome to Rufus Taxi Services, operated by Rufus Taxi Services LTD and
        digitally supported by Waway LTD (hereafter referred to as "Company,"
        "we," "our," or "us"). By using our taxi booking website and services,
        you agree to comply with and be bound by the following terms and
        conditions ("Terms"). Please review these Terms carefully. If you do not
        agree to them, you should not use our services.
      </p>
      <h3>2. Nature of Service</h3>
      <p>
        Rufus Taxi Service acts as a "trip organizing agency", facilitating the
        booking of taxi services between Passengers ("Passenger", "You") and
        independent Drivers ("Driver"). All payments for the trips are made
        directly to the Driver.
      </p>
      <h3>3. Payments</h3>
      <p>
        Drivers are required to have card readers to accept cashless payments.
        The quoted price provided at the time of booking is the amount to be
        paid directly to the Driver. Airport parking and toll charges are
        included in the quoted price.
      </p>
      <h3>4. Waiting Time Charges</h3>
      <p>
        Any waiting time charges encountered during the trip will be added to
        the quoted price.
      </p>
      <p>
        BRISTOL AIRPORT: 45 minutes free since plane landed. After 45 minutes:
        every 15 minutes waiting time charge - £7.
      </p>
      <p>
        LONDON AIRPORTS: 1h free since plane landed. After 1 hour: every 15
        minutes waiting time charge - £7.
      </p>
      <p>
        FOR OTHER BOOKINGS 10 minutes free, after that - £5 for every 10
        minutes.
      </p>
      <p>
        Customer must pay waiting charge fee directly to the Driver cash or
        card.
      </p>
      <h3>5. Special Driver Requests</h3>
      <p>
        If a Passenger requests a particular Driver, the Passenger's phone
        number will be shared with the requested Driver, and the Driver's phone
        number will be shared with the Passenger to facilitate communication.
      </p>
      <h3>6. General Booking Requests</h3>
      <p>
        Passengers have the option to post a booking request without specifying
        a particular Driver. In such cases, the request will be posted on the
        Drivers' "wall," and the Passenger's phone number will be shared with
        all approved Drivers. This allows Drivers to contact the Passenger for
        any additional information about the booking request before accepting
        it.
      </p>
      <h3>7. Licensing and Compliance</h3>
      <p>
        Our company will do its best to ensure that the Drivers and their
        vehicles are licensed, insured, and comply with all relevant legislation
        and trade standards.
      </p>
      <h3>8. User Conduct</h3>
      <p>By using our services, you agree to:</p>
      <ul>
        <li>
          The way we deal with Your data disclosed in our{" "}
          <a href="https://rufus.taxi/privacy-policy">
            <b>Privacy Policy</b>
          </a>
        </li>
        <li>Provide accurate and complete information at all times.</li>
        <li>Comply with all applicable laws and regulations.</li>
        <li>Respect the privacy and personal information of other users.</li>
        <li>
          Not engage in any conduct that is harmful, unlawful, or disruptive to
          our services or other users.
        </li>
      </ul>
      <h3>9. Limitation of Liability</h3>
      <p>
        To the maximum extent permitted by law, Waway LTD shall not be liable
        for any indirect, incidental, special, consequential, or punitive
        damages, or any loss of profits or revenues, whether incurred directly
        or indirectly, or any loss of data, use, goodwill, or other intangible
        losses, resulting from:
      </p>
      <ul>
        <li>Your use of or inability to use our services.</li>
        <li>
          Any unauthorized access to or use of our servers and/or any personal
          information stored therein.
        </li>
        <li>
          Any interruption or cessation of transmission to or from our services.
        </li>
        <li>
          Any bugs, viruses, trojan horses, or the like that may be transmitted
          to or through our services by any third party.
        </li>
        <li>
          Any errors or omissions in any content or for any loss or damage
          incurred as a result of the use of any content posted, emailed,
          transmitted, or otherwise made available through our services.
        </li>
      </ul>
      <h3>10. Passenger Responsibility and Cleaning Fee Policy</h3>
      <p>
        At Rufus Taxi Services, we are dedicated to providing a clean and
        comfortable environment for all our passengers. To maintain this
        standard, we kindly request that all passengers adhere to the following
        guidelines:
      </p>
      <p>Passenger Responsibilities:</p>
      <ul>
        <li>
          Cleanliness: Please avoid actions that may soil or damage the
          vehicle's interior. This includes refraining from eating, drinking, or
          any behavior that could result in spills, stains, or other messes.
        </li>
        <li>
          Illness: If you are feeling unwell, especially due to alcohol
          consumption or other reasons, please inform the driver immediately.
          The driver may choose to stop the vehicle to allow you to safely exit
          and prevent any soiling of the taxi.
        </li>
      </ul>
      <p>Cleaning Fees:</p>
      <p>
        In the event that a passenger soils the vehicle, rendering it unfit for
        immediate use, a cleaning fee will be charged to cover the cost of
        professional cleaning and compensate for the driver's loss of earnings
        during the downtime.
      </p>
      <ul>
        <li>
          Standard Cleaning Fee: A charge of <strong>£100</strong> will be
          applied if a passenger vomits or causes a similar level of soiling in
          the vehicle. This fee is in line with industry standards and reflects
          the costs associated with cleaning and potential loss of income.
        </li>
        <li>
          Minor Soiling: For less severe incidents, such as minor spills or
          stains, a reduced fee may be applied at the driver's discretion, based
          on the extent of the cleaning required.
        </li>
      </ul>
      <p>Payment of Fees:</p>
      <p>
        The cleaning fee will be added to your fare and must be settled at the
        end of your journey. In cases where immediate payment is not possible,
        RUFUS Taxi Services reserves the right to invoice the passenger, and
        non-payment may result in legal action to recover the costs.
      </p>
      <p>Disputes:</p>
      <p>
        If you wish to dispute a cleaning fee, please contact our customer
        service team within 48 hours of your journey. Provide detailed
        information and any supporting evidence for review.
      </p>
      <p>
        Our aim is to ensure a pleasant experience for all customers, and we
        appreciate your cooperation in maintaining the cleanliness and integrity
        of our vehicles.
      </p>
      <h3>11. Modifications to Terms</h3>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material, we will provide at least
        30 days' notice prior to any new terms taking effect. What constitutes a
        material change will be determined at our sole discretion. By continuing
        to access or use our services after any revisions become effective, you
        agree to be bound by the revised terms.
      </p>
      <h3>12. Contact Us</h3>
      <p>
        If you have any questions about these Terms, please contact us by
        filling our{" "}
        <strong>
          <Link to="/contact">Contact Form</Link>
        </strong>
      </p>
      <p>Or reach out by post.</p>
      <p>
        <strong>Address:</strong> <br />
        Waway LTD
        <br />
        2530 Aztec West (Ground Floor)
        <br />
        Almondsbury
        <br />
        BS32 4AW
        <br />
        Bristol
        <br />
        UK
      </p>
    </Card>
  );
};

export default Terms;
