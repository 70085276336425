import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { FaDog, FaEdit, FaExclamationTriangle } from "react-icons/fa";
import { MdEmojiPeople } from "react-icons/md";

import { firestore } from "../../../firebase/config";
import {
  AmberHeader,
  BackButtonContainer,
  ButtonColumnContainer,
  ClickableArea,
  GreenHeader,
  GreenP,
  RedHeader,
  RedP,
  SpreadButtonContainer,
  TextDivider,
} from "../../../global.styles";
import { RootState } from "../../../redux/store";
import { BookingType } from "../../../redux/bookings/bookings.types";
import Addresses from "../../bookings/addresses.component";
import { defineVehicleType } from "../../../util-functions";
import Card from "../../UI/card/card.component";
import Spinner from "../../UI/spinner/spinner.component";
import BookingStatusheader from "../../bookings/booking-status/booking-status-header";
import Button from "../../UI/button/button.component";
import {
  cancelJobRequest,
  driversBackAction,
  driversNextAction,
  markJobNoShow,
} from "../../../firebase/user";
import FormInput from "../../UI/form-input/form-input.component";
import InnerCard from "../../UI/inner-card/inner-card.component";
import AssignDriver from "../../admin/forms/assign-driver.component";
import { colors } from "../../../style-variables";
import { makeJobPublic } from "../../../firebase/bookings";
import AddBookingNote from "../../admin/forms/add-booking-note.component";
import TollDetails from "../../bookings/tollDetails.component";
import RadioButton from "../../UI/radio-buttons/radio-button";

//function to determine text for "Next" action buttton
const nextButtonText = (job: BookingType): string => {
  if (job.pickedUp) {
    return "COMPLETE JOB";
  } else if (job.arrivedToPickup) {
    return "PICKED UP";
  } else if (job.onRoute) {
    return "ARRIVED";
  } else {
    return "ON ROUTE";
  }
};

const AcceptedJobDetails = () => {
  const navigate = useNavigate();

  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);

  const { jobId } = useParams();

  const [actionLoading, setActionLoading] = useState(false);
  const [actionError, setActionError] = useState("");
  const [jobCancelled, setJobCancelled] = useState(false);
  const [showRejectForm, setShowRejectForm] = useState(false);
  const [reasonOfReject, setReasonOfReject] = useState("");
  const [showAssignDriverForm, setShowAssignDriverForm] = useState(false);
  const [showMakePublicForm, setShowMakePublicForm] = useState(false);
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [showNoshowForm, setShowNoshowForm] = useState(false);
  const [noShowReason, setNoShowReason] = useState("");
  const [loadingNoShow, setLoadingNoShow] = useState(false);
  const [noShowError, setNoShowError] = useState("");
  const [noShowSuccess, setNoShowSuccess] = useState(false);
  const [jobRedirected, setJobRedirected] = useState(false);
  const [loadingPublic, setLoadingPublic] = useState(false);
  const [makePublicError, setMakePublicError] = useState("");
  const [showAddNoteForm, setShowAddNoteForm] = useState(false);

  const [jobDetailsLoading, setJobDetailsLoading] = useState(true);
  const [jobDetailsError, setJobDetailsError] = useState("");
  const [jobDetails, setJobDetails] = useState<BookingType | null>(null);

  //set onSnapshot (real time updates connection) for this job
  useEffect(() => {
    if (jobId && approvedDriver) {
      const unsubscribe = onSnapshot(
        doc(firestore, "bookings", jobId),
        { includeMetadataChanges: true },
        (jobDoc) => {
          if (jobDoc.exists()) {
            // Handle document existence
            if (!jobDoc.metadata.hasPendingWrites) {
              // Data is up-to-date
              const updatedBooking = {
                id: jobDoc.id,
                ...jobDoc.data(),
                createdAt: jobDoc.data().createdAt.toMillis(),
              };
              setJobDetails(updatedBooking as BookingType);
              setJobDetailsLoading(false);
            } else if (
              jobDoc.metadata.hasPendingWrites &&
              !jobDoc.metadata.fromCache
            ) {
              // Data is being updated, show a loading indicator
              setJobDetailsLoading(true); // Loading state
              // Clear existing data
              // ... (Display a loading indicator)
            } else if (
              jobDoc.metadata.hasPendingWrites &&
              jobDoc.metadata.fromCache
            ) {
              // Data is outdated, refresh from the server
              setJobDetailsLoading(true); // Loading state
              // Trigger a refresh from the server
              getDoc(doc(firestore, "bookings", jobId))
                .then((docSnap) => {
                  if (docSnap.exists()) {
                    const updatedBooking = {
                      id: docSnap.id,
                      ...docSnap.data(),
                      createdAt: docSnap.data()!.createdAt.toMillis(),
                      acceptedAt: docSnap.data()!.acceptedAt.toMillis(),
                      arrivedToPickupAt: docSnap
                        .data()!
                        .arrivedToPickupAt.toMillis(),
                      pickedUpAt: docSnap.data()!.pickedUpAt.toMillis(),
                      completedAt: docSnap.data()!.completedAt.toMillis(),
                      //convert pickupGeoPoint into serializable object for Redux store
                      pickupGeoPoint: {
                        latitude: docSnap.data()!.pickupGeoPoint.latitude,
                        longitude: docSnap.data()!.pickupGeoPoint.longitude,
                      },
                    };
                    setJobDetails(updatedBooking as BookingType);
                    setJobDetailsLoading(false);
                  } else {
                    // Handle document not found
                    setJobDetailsError("Job not found");
                    setJobDetailsLoading(false);
                    // ... (Display an error message or redirect)
                  }
                })
                .catch((error) => {
                  if (error instanceof Error) {
                    setJobDetailsError(error.message);
                    setJobDetailsLoading(false);
                  } else {
                    setJobDetailsError("Error fetching job");
                    setJobDetailsLoading(false);
                  }
                });
            }
          } else {
            // Handle document not found
            setJobDetailsError("Job not found");
            setJobDetailsLoading(false);
            // ... (Display an error message or redirect)
          }
        },
        (error) => {
          if (error instanceof Error) {
            setJobDetailsError(error.message);
            setJobDetailsLoading(false);
          } else {
            setJobDetailsError("Error fetching a job");
            setJobDetailsLoading(false);
          }
        }
      );
      return () => unsubscribe();
    }
  }, [approvedDriver, jobId, navigate]);

  const reasonChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    setReasonOfReject(event.target.value);

  const rejectActionHandler = async () => {
    if (!reasonOfReject || reasonOfReject === "") {
      setActionError(
        "Please share your reason for cancelling this booking to help us improve."
      );
      return;
    }
    if (jobId) {
      setActionLoading(true);
      try {
        const data = await cancelJobRequest(jobId, reasonOfReject);
        if (data && data.message && data.message === "Job cancelled!") {
          setJobCancelled(true);
        }
      } catch (error) {
        if (error instanceof Error) {
          setActionError(error.message);
        } else {
          setActionError(
            "Problem cancelling this job... Please try again. If problem still exists, please let us know"
          );
        }
      }
      setActionLoading(false);
    } else {
      navigate("/drivers/accepted-jobs");
    }
  };

  const cancelActionHandler = async () => {
    if (jobId) {
      setActionError("");
      setActionLoading(true);
      try {
        await driversBackAction(jobId);
      } catch (error) {
        if (error instanceof Error) {
          setActionError(error.message);
        } else {
          setActionError(
            "Problem reverting the job... Please try again. If problem still exists, please let us know"
          );
        }
      }
      setActionLoading(false);
    } else {
      navigate("/drivers/driver-panel");
    }
  };

  const forwardActionHandler = async () => {
    if (jobId) {
      setActionError("");
      setActionLoading(true);
      try {
        await driversNextAction(jobId);
      } catch (error) {
        if (error instanceof Error) {
          setActionError(error.message);
        } else {
          setActionError(
            "Problem progressing the job... Please try again. If problem still exists, please let us know"
          );
        }
      }
      setActionLoading(false);
    } else {
      navigate("/drivers/driver-panel");
    }
  };

  const makePublicHandler = async () => {
    if (jobId) {
      setLoadingPublic(true);
      try {
        await makeJobPublic(jobId);
        setMakePublicError("");
        setJobRedirected(true);
      } catch (error) {
        if (error instanceof Error) {
          setMakePublicError(error.message);
        } else {
          setMakePublicError("Problem making this job public...");
        }
      } finally {
        setLoadingPublic(false);
      }
    } else {
      navigate("/drivers/driver-panel");
    }
  };

  const noShowReasonHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setNoShowReason(event.target.value);
  };

  const noShowSubmitHandler = async () => {
    if (jobId) {
      if (!noShowReason || noShowReason === "") {
        setNoShowError("Please select a reason for No Show.");
        return;
      }
      setLoadingNoShow(true);
      try {
        await markJobNoShow(jobId, noShowReason);
        setNoShowReason("");
        setShowNoshowForm(false);
        setNoShowError("");
        setNoShowSuccess(true);
      } catch (error) {
        if (error instanceof Error) {
          setNoShowError(error.message);
        } else {
          setNoShowError("Problem setting No Show...");
        }
      } finally {
        setLoadingNoShow(false);
      }
    } else {
      navigate("/drivers/driver-panel");
    }
  };

  if (jobRedirected) {
    return (
      <Card>
        <GreenHeader>Job successfully redirected!</GreenHeader>
        <BackButtonContainer>
          <Button
            buttonType="green"
            onClick={() => navigate("/drivers/accepted-jobs")}
          >
            OK
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  if (jobCancelled) {
    return (
      <Card>
        <GreenHeader>Job cancelled.</GreenHeader>
        <BackButtonContainer>
          <Button
            buttonType="green"
            onClick={() => {
              navigate("/drivers/accepted-jobs");
            }}
          >
            OK
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  if (noShowSuccess) {
    return (
      <Card>
        <GreenHeader>Job marked as NO SHOW.</GreenHeader>
        <BackButtonContainer>
          <Button
            buttonType="green"
            onClick={() => {
              navigate("/drivers/accepted-jobs");
            }}
          >
            OK
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  //we don't display job as active with all controlls if it's rejected or returned
  if (
    !jobDetailsLoading &&
    !actionLoading &&
    !!jobDetails &&
    (!jobDetails.accepted || jobDetails.completed)
  ) {
    return (
      <Card>
        <GreenHeader>
          {jobDetails?.cancelledByPassenger
            ? "Your job was cancelled by passenger, sorry."
            : "Job completed."}
        </GreenHeader>
        <BackButtonContainer>
          <Button
            buttonType="green"
            onClick={() => {
              navigate("/drivers/accepted-jobs");
            }}
          >
            OK
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  return (
    <Card>
      {(approvedDriverLoading || actionLoading || jobDetailsLoading) && (
        <Spinner />
      )}
      {approvedDriverError && !approvedDriverLoading && (
        <RedHeader>{approvedDriverError}</RedHeader>
      )}
      {jobDetailsError && !jobDetailsLoading && (
        <RedHeader>{jobDetailsError}</RedHeader>
      )}
      {!jobDetailsLoading && !actionLoading && !!jobDetails && (
        <>
          <BackButtonContainer>
            <ClickableArea onClick={() => navigate(-1)}>
              <h3>Go Back</h3>
            </ClickableArea>
          </BackButtonContainer>
          <GreenP>Your job on {jobDetails.date} at</GreenP>
          <GreenHeader>{jobDetails.time}</GreenHeader>
          <TextDivider>
            <BookingStatusheader booking={jobDetails} />
          </TextDivider>
          {actionError && !actionLoading && (
            <RedHeader>{actionError}</RedHeader>
          )}
          {!showNoshowForm && //don't show buttons if No Show form is visible
            (!showRejectForm ? (
              <SpreadButtonContainer>
                {!jobDetails.onRoute ? (
                  <Button
                    buttonType="inverted"
                    onClick={() => {
                      setShowRejectForm(true);
                      setActionError("");
                    }}
                  >
                    {jobDetails.publicBooking ? "Return Job" : "Redirect Job"}
                  </Button>
                ) : (
                  <Button buttonType="inverted" onClick={cancelActionHandler}>
                    Undo
                  </Button>
                )}
                <Button onClick={forwardActionHandler}>
                  {nextButtonText(jobDetails)}
                </Button>
              </SpreadButtonContainer>
            ) : jobDetails.publicBooking ? (
              <>
                <AmberHeader>Return this job?</AmberHeader>
                <GreenP>
                  This job was booked as a Public Job. By pressing{" "}
                  <strong>Return Job</strong>, it will be returned to the Public
                  Jobs List and will no longer be assigned to You.
                </GreenP>
                <FormInput
                  label="Please type the reason..."
                  onChange={reasonChangeHandler}
                  id="reasonOfReject"
                  name="reasonOfReject"
                  type="text"
                  value={reasonOfReject}
                  required
                />
                <SpreadButtonContainer>
                  <Button
                    buttonType="inverted"
                    onClick={() => {
                      setShowRejectForm(false);
                      setActionError("");
                    }}
                  >
                    Keep The Job
                  </Button>
                  <Button onClick={rejectActionHandler}>Return Job</Button>
                </SpreadButtonContainer>
              </>
            ) : (
              <>
                <AmberHeader>
                  What would You like to do with this job?
                </AmberHeader>
                <ButtonColumnContainer>
                  <Button
                    onClick={() => {
                      setShowRejectForm(false);
                      setActionError("");
                    }}
                  >
                    Keep the Job
                  </Button>
                  <AmberHeader>OR...</AmberHeader>
                </ButtonColumnContainer>
                <InnerCard
                  onClick={() => setShowAssignDriverForm(!showAssignDriverForm)}
                >
                  <h3>Send it to another Driver</h3>
                </InnerCard>
                {showAssignDriverForm && (
                  <>
                    <FaExclamationTriangle size={24} color={colors.primary} />
                    <GreenP>
                      Please make sure that the Driver You are sending this job
                      to, is informed and is willing to do it!
                    </GreenP>
                    <AssignDriver
                      bookingId={jobDetails.id!}
                      onDriverAssigned={() => {
                        setShowAssignDriverForm(false);
                        setJobRedirected(true);
                      }}
                      onCancel={() => setShowAssignDriverForm(false)}
                    />
                  </>
                )}
                <InnerCard
                  onClick={() => setShowMakePublicForm(!showMakePublicForm)}
                >
                  <h3>Make it Public</h3>
                </InnerCard>
                {showMakePublicForm && (
                  <>
                    <GreenP>
                      <strong>Note:</strong> When You make this job Public, it
                      will no longer be treated as a Personal Request to You and
                      will instead become available to all our approved Drivers.
                    </GreenP>
                    {makePublicError && <RedP>{makePublicError}</RedP>}
                    {loadingPublic ? (
                      <Spinner />
                    ) : (
                      <SpreadButtonContainer>
                        <Button
                          buttonType="inverted"
                          onClick={() => {
                            setShowMakePublicForm(false);
                            setMakePublicError("");
                          }}
                        >
                          Go Back
                        </Button>
                        <Button onClick={makePublicHandler}>
                          Make it Public
                        </Button>
                      </SpreadButtonContainer>
                    )}
                  </>
                )}
                <InnerCard onClick={() => setShowCancelForm(!showCancelForm)}>
                  <h3>Cancel Job</h3>
                </InnerCard>
                {showCancelForm && (
                  <>
                    <AmberHeader>Cancel this job?</AmberHeader>
                    <FaExclamationTriangle size={24} color={colors.primary} />
                    <GreenP>
                      Please ensure You have a valid reason to cancel this job,
                      rather than passing it to another Driver or making it
                      publicly available to all approved Drivers.
                    </GreenP>
                    <FormInput
                      label="Please type the reason..."
                      onChange={reasonChangeHandler}
                      id="reasonOfReject"
                      name="reasonOfReject"
                      type="text"
                      value={reasonOfReject}
                      required
                    />
                    <SpreadButtonContainer>
                      <Button
                        buttonType="inverted"
                        onClick={() => {
                          setShowCancelForm(false);
                        }}
                      >
                        Go Back
                      </Button>
                      <Button onClick={rejectActionHandler}>Cancel Job</Button>
                    </SpreadButtonContainer>
                  </>
                )}
              </>
            ))}
          {loadingNoShow ? (
            <Spinner />
          ) : !showNoshowForm ? (
            <ButtonColumnContainer>
              <Button
                buttonType="inverted"
                onClick={() => setShowNoshowForm(true)}
              >
                No Show
              </Button>
            </ButtonColumnContainer>
          ) : (
            <>
              <AmberHeader>Reason for no-show:</AmberHeader>
              <RadioButton
                label="Passenger not at location"
                id="no-passenger"
                name="noShowReason"
                value="Passenger not at location"
                onChange={noShowReasonHandler}
              />
              <RadioButton
                label="Passenger refused ride"
                id="refused-ride"
                name="noShowReason"
                value="Passenger refused ride"
                onChange={noShowReasonHandler}
              />
              <RadioButton
                label="Wrong address"
                id="wrong-address"
                name="noShowReason"
                value="Wrong address"
                onChange={noShowReasonHandler}
              />
              <FaExclamationTriangle size={24} color={colors.primary} />
              <GreenP>
                Important! Please, keep proof (e.g., location screenshot, call
                logs) to resolve any future disputes.
              </GreenP>
              {noShowError && <RedP>{noShowError}</RedP>}
              <SpreadButtonContainer>
                <Button
                  buttonType="inverted"
                  onClick={() => setShowNoshowForm(false)}
                >
                  Cancel
                </Button>
                <Button buttonType="warning" onClick={noShowSubmitHandler}>
                  Confirm No Show
                </Button>
              </SpreadButtonContainer>
            </>
          )}
          <TextDivider>
            <GreenP> ADDRESSES </GreenP>
          </TextDivider>
          <Addresses booking={jobDetails} />
          <TextDivider>
            <GreenP> DETAILS </GreenP>
          </TextDivider>
          <GreenP>Passenger Name</GreenP>
          <p>{jobDetails.passengerName}</p>
          <br />
          <GreenP>Passenger Phone Number</GreenP>
          <p>{jobDetails.passengerPhone}</p>
          <br />
          <GreenP>Vehicle Type:</GreenP>
          <p>{defineVehicleType(jobDetails.vehicleType)}</p>
          {jobDetails.meetAndGreet && (
            <>
              <br />
              <GreenP>
                <MdEmojiPeople size={32} /> Meet & Greet requested.
              </GreenP>
            </>
          )}
          {jobDetails.hasPet && (
            <>
              <br />
              <GreenP>
                <FaDog size={24} /> Booking includes pet.
              </GreenP>
            </>
          )}
          {jobDetails.driverNote && (
            <>
              <br />
              <GreenP>Note to the Driver</GreenP>
              <p>{jobDetails.driverNote}</p>
            </>
          )}
          {jobDetails.additionalNote && (
            <>
              <br />
              <GreenP>Additional note:</GreenP>
              <p>{jobDetails.additionalNote}</p>
            </>
          )}
          <br />
          <ClickableArea onClick={() => setShowAddNoteForm(!showAddNoteForm)}>
            <GreenP>
              {jobDetails.additionalNote ? "Edit" : "Add"} Note <FaEdit />
            </GreenP>
          </ClickableArea>
          {showAddNoteForm && jobId && (
            <AddBookingNote
              bookingId={jobId}
              currentNote={jobDetails.additionalNote || ""}
              onNoteAdded={() => setShowAddNoteForm(false)}
            />
          )}
          <TextDivider>
            <GreenP> PRICE </GreenP>
          </TextDivider>
          <GreenHeader> £{jobDetails.quotedPrice}</GreenHeader>
          <TollDetails booking={jobDetails} />
        </>
      )}
    </Card>
  );
};

export default AcceptedJobDetails;
