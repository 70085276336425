import { FC } from "react";

import { BookingType } from "../../redux/bookings/bookings.types";
import { SpacerVertical } from "../UI/spacers/spacers.component";
import { GreenP } from "../../global.styles";

type TollDetailsProps = {
  booking: BookingType;
};

const TollDetails: FC<TollDetailsProps> = ({ booking }) => {
  return (
    <>
      {booking.tollInfo && booking.tollInfo.totalCharges > 0 && (
        <SpacerVertical $paddingRem={1}>
          <GreenP>The price above already includes:</GreenP>
          {booking.tollInfo.airportNames?.length > 0 && (
            <GreenP>
              Parking for:{" "}
              {(booking.tollInfo?.airportNames ?? []).map((name, index) => (
                <strong key={`airport-${index}`}>
                  {name}
                  {index < (booking.tollInfo?.airportNames?.length ?? 0) - 1
                    ? ", "
                    : ""}
                </strong>
              ))}
            </GreenP>
          )}
          {booking.tollInfo.tollChargeNames?.length > 0 && (
            <GreenP>
              Toll charges for:{" "}
              {(booking.tollInfo?.tollChargeNames ?? []).map((name, index) => (
                <strong key={`toll-${index}`}>
                  {name}
                  {index < (booking.tollInfo?.tollChargeNames?.length ?? 0) - 1
                    ? ", "
                    : ""}
                </strong>
              ))}
            </GreenP>
          )}
        </SpacerVertical>
      )}
    </>
  );
};

export default TollDetails;
