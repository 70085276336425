import { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import { doc, getDoc, onSnapshot } from "firebase/firestore";

import { firestore } from "../../firebase/config";
import { BookingType } from "../../redux/bookings/bookings.types";
// import { RootState } from "../../redux/store";
// import {
//   errorBookingDetailsRealTime,
//   loadingBookingDetails,
//   updateBookingDetailsRealTime,
// } from "../../redux/booking-details/bookingDetails.slice";
import Card from "../UI/card/card.component";
import Spinner from "../UI/spinner/spinner.component";
import {
  AmberHeader,
  BackButtonContainer,
  ClickableArea,
  GreenHeader,
  GreenP,
  RedHeader,
  RedP,
  SpreadButtonContainer,
  TextDivider,
} from "../../global.styles";
import BookingStatus from "../bookings/booking-status/booking-status.component";
import Addresses from "../bookings/addresses.component";
import { defineVehicleType } from "../../util-functions";
import Button from "../UI/button/button.component";
import DriverInfo from "../bookings/driver-info.component";
import { makeJobPublic } from "../../firebase/bookings";
import InnerCard from "../UI/inner-card/inner-card.component";
import ChangeBookingPrice from "./forms/change-booking-price.component";
import AssignDriver from "./forms/assign-driver.component";
import { FaEdit, FaExclamationTriangle } from "react-icons/fa";
import { colors } from "../../style-variables";
import FormInput from "../UI/form-input/form-input.component";
import { cancelJobByAdmin } from "../../firebase/admin";
import AddBookingNote from "./forms/add-booking-note.component";
import TollDetails from "../bookings/tollDetails.component";

const BookingDetails = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const { bookingId } = useParams();

  // //Can't find the reason of keeping this state global
  // const { bookingDetailsLoading, bookingDetailsError, bookingDetails } =
  //   useSelector((state: RootState) => state.bookingDetails);

  const [bookingDetailsLoading, setBookingDetailsLoading] = useState(true);
  const [bookingDetailsError, setBookingDetailsError] = useState("");
  const [bookingDetails, setBookingDetails] = useState<BookingType | null>(
    null
  );
  const [bookingCancelled, setBookingCancelled] = useState(false);

  //states of admin actions
  const [showAddNoteForm, setShowAddNoteForm] = useState(false);
  const [showPriceChangeForm, setShowPriceChangeForm] = useState(false);
  const [showAssignDriverForm, setShowAssignDriverForm] = useState(false);
  const [showMakePublicForm, setShowMakePublicForm] = useState(false);
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [reasonOfReject, setReasonOfReject] = useState("");
  const [cancelJobError, setCancelJobError] = useState("");
  const [loadingPublic, setLoadingPublic] = useState(false);
  const [makePublicError, setMakePublicError] = useState("");
  const [jobMadePublic, setJobMadePublic] = useState(false);

  //set onSnapshot (real time updates connection) for this booking
  useEffect(() => {
    if (bookingId) {
      const unsubscribe = onSnapshot(
        doc(firestore, "bookings", bookingId),
        { includeMetadataChanges: true },
        (bookingDoc) => {
          if (bookingDoc.exists()) {
            // Handle document existence
            if (!bookingDoc.metadata.hasPendingWrites) {
              // Data is up-to-date
              const updatedBooking = {
                id: bookingDoc.id,
                ...bookingDoc.data(),
                createdAt: bookingDoc.data().createdAt.toMillis(),
              };
              setBookingDetails(updatedBooking as BookingType);
              setBookingDetailsLoading(false);
            } else if (
              bookingDoc.metadata.hasPendingWrites &&
              !bookingDoc.metadata.fromCache
            ) {
              // Data is being updated, show a loading indicator
              setBookingDetailsLoading(true); // Loading state
              // Clear existing data
              // ... (Display a loading indicator)
            } else if (
              bookingDoc.metadata.hasPendingWrites &&
              bookingDoc.metadata.fromCache
            ) {
              // Data is outdated, refresh from the server
              setBookingDetailsLoading(true); // Loading state
              // Trigger a refresh from the server
              getDoc(doc(firestore, "bookings", bookingId))
                .then((docSnap) => {
                  if (docSnap.exists()) {
                    const updatedBooking = {
                      id: docSnap.id,
                      ...docSnap.data(),
                      createdAt: docSnap.data()!.createdAt.toMillis(),
                    };
                    setBookingDetails(updatedBooking as BookingType);
                    setBookingDetailsLoading(false);
                  } else {
                    // Handle document not found
                    setBookingDetailsError("Booking not found");
                    setBookingDetailsLoading(false);
                    // ... (Display an error message or redirect)
                  }
                })
                .catch((error) => {
                  if (error instanceof Error) {
                    setBookingDetailsError(error.message);
                    setBookingDetailsLoading(false);
                  } else {
                    setBookingDetailsError("Error fetching booking");
                    setBookingDetailsLoading(false);
                  }
                });
            }
          } else {
            // Handle document not found
            setBookingDetailsError("Booking not found");
            setBookingDetailsLoading(false);
            // ... (Display an error message or redirect)
          }
        },
        (error) => {
          if (error instanceof Error) {
            setBookingDetailsError(error.message);
            setBookingDetailsLoading(false);
          } else {
            setBookingDetailsError("Error fetching a booking");
            setBookingDetailsLoading(false);
          }
        }
      );
      return () => unsubscribe();
    } else {
      navigate("/404");
    }
  }, [bookingId, navigate]);

  //make send the job to public list
  const makePublicHandler = async () => {
    if (bookingId) {
      setLoadingPublic(true);
      try {
        await makeJobPublic(bookingId);
        setMakePublicError("");
        setJobMadePublic(true);
      } catch (error) {
        if (error instanceof Error) {
          setMakePublicError(error.message);
        } else {
          setMakePublicError("Problem making this job public...");
        }
      } finally {
        setLoadingPublic(false);
      }
    }
  };

  //cancel job
  const reasonChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    setReasonOfReject(event.target.value);
  const cancelJobHandler = async () => {
    if (!reasonOfReject || reasonOfReject === "") {
      setCancelJobError("Please enter Your reason for cancelling this job.");
      return;
    }
    if (bookingId) {
      setBookingDetailsLoading(true);
      try {
        const data = await cancelJobByAdmin(bookingId, reasonOfReject);
        if (data && data.message && data.message === "Booking cancelled!") {
          setBookingCancelled(true);
        }
      } catch (error) {
        if (error instanceof Error) {
          setCancelJobError(error.message);
        } else {
          setCancelJobError(
            "Problem cancelling this booking... Please try again. If problem still exists, please let us know"
          );
        }
      }
      setBookingDetailsLoading(false);
    }
  };

  if (jobMadePublic) {
    return (
      <Card>
        <GreenHeader>Booking is sent to Public Job List.</GreenHeader>
        <BackButtonContainer>
          <Button
            buttonType="green"
            onClick={() => {
              navigate("/boss");
            }}
          >
            OK
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  if (bookingCancelled) {
    return (
      <Card>
        <GreenHeader>Booking cancelled.</GreenHeader>
        <BackButtonContainer>
          <Button
            buttonType="green"
            onClick={() => {
              navigate("/bookings");
            }}
          >
            OK
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  return (
    <Card>
      {bookingDetailsLoading && <Spinner />}
      {bookingDetailsError && (
        <>
          <RedHeader>{bookingDetailsError}</RedHeader>
          <BackButtonContainer>
            <Button onClick={() => navigate("/boss/manage-bookings")}>
              Ok
            </Button>
          </BackButtonContainer>
        </>
      )}
      {!bookingDetailsError && !bookingDetailsLoading && !bookingDetails && (
        <Card>
          <RedHeader>Wrong Parameters...</RedHeader>
          <Button onClick={() => navigate("/boss")}>Try Again</Button>
        </Card>
      )}
      {bookingDetails &&
        bookingId &&
        !bookingDetailsLoading &&
        !bookingDetailsError && (
          <>
            <BackButtonContainer>
              <Link to="/boss">
                <h3>&larr; Admin Menu</h3>
              </Link>
            </BackButtonContainer>
            <GreenHeader>
              Booking on {bookingDetails?.date} at {bookingDetails?.time}
            </GreenHeader>
            <BookingStatus booking={bookingDetails} />
            {bookingDetails.reasonOfReject && (
              <>
                <TextDivider>
                  <GreenP> REASON OF REJECT</GreenP>
                </TextDivider>
                <GreenP>
                  <strong>{bookingDetails.reasonOfReject}</strong>
                </GreenP>
              </>
            )}
            {bookingDetails.noShowReason && (
              <>
                <TextDivider>
                  <GreenP> NO SHOW REASON </GreenP>
                </TextDivider>
                <GreenP>
                  <strong>{bookingDetails.noShowReason}</strong>
                </GreenP>
              </>
            )}
            <TextDivider>
              <GreenP> DRIVER INFO </GreenP>
            </TextDivider>
            {bookingDetails.driverNumber && !bookingDetails.publicBooking && (
              <GreenP>
                <strong>
                  Private request to Driver {bookingDetails.driverNumber}
                </strong>
              </GreenP>
            )}
            {bookingDetails.accepted && bookingDetails.acceptedBy ? (
              <DriverInfo driver={bookingDetails.acceptedBy} />
            ) : (
              <GreenP>
                Driver Info is not available untill Driver accepts the
                booking...
              </GreenP>
            )}
            <TextDivider>
              <GreenP> ADDRESSES </GreenP>
            </TextDivider>
            <Addresses booking={bookingDetails} />
            <TextDivider>
              <GreenP> DETAILS </GreenP>
            </TextDivider>
            <GreenP>Passenger Name</GreenP>
            <p>{bookingDetails.passengerName}</p>
            <br />
            <GreenP>Passenger Phone Number</GreenP>
            <p>{bookingDetails.passengerPhone}</p>
            <br />
            <GreenP>Vehicle Type:</GreenP>
            <p>{defineVehicleType(bookingDetails.vehicleType)}</p>
            {bookingDetails.hasPet && (
              <>
                <br />
                <GreenP>Pet Friendly</GreenP>
                <p>Yes</p>
              </>
            )}
            {bookingDetails.meetAndGreet && (
              <>
                <br />
                <GreenP>Meet & Greet requested</GreenP>
                <p>Yes</p>
              </>
            )}
            {bookingDetails.driverNote && (
              <>
                <br />
                <GreenP>Note to the Driver</GreenP>
                <p>{bookingDetails.driverNote}</p>
              </>
            )}
            {bookingDetails.additionalNote && (
              <>
                <br />
                <GreenP>Additional note:</GreenP>
                <p>{bookingDetails.additionalNote}</p>
              </>
            )}
            <br />
            <ClickableArea onClick={() => setShowAddNoteForm(!showAddNoteForm)}>
              <GreenP>
                {bookingDetails.additionalNote ? "Edit" : "Add"} Note <FaEdit />
              </GreenP>
            </ClickableArea>
            {showAddNoteForm && (
              <AddBookingNote
                bookingId={bookingId}
                currentNote={bookingDetails.additionalNote || ""}
                onNoteAdded={() => setShowAddNoteForm(false)}
              />
            )}
            <TextDivider>
              <GreenP> PRICE </GreenP>
            </TextDivider>
            <GreenHeader> £{bookingDetails.quotedPrice}</GreenHeader>
            <TollDetails booking={bookingDetails} />
            <>
              <TextDivider>
                <GreenP> ADMIN ACTIONS </GreenP>
              </TextDivider>
              <InnerCard
                onClick={() => setShowPriceChangeForm(!showPriceChangeForm)}
              >
                <h3>Change Price</h3>
              </InnerCard>
              {showPriceChangeForm && (
                <ChangeBookingPrice
                  bookingId={bookingId}
                  currentPrice={bookingDetails.quotedPrice}
                  onPriceReviewed={() => setShowPriceChangeForm(false)}
                />
              )}
              <InnerCard
                onClick={() => setShowAssignDriverForm(!showAssignDriverForm)}
              >
                <h3>Assign Driver</h3>
              </InnerCard>
              {showAssignDriverForm && (
                <AssignDriver
                  bookingId={bookingId}
                  onDriverAssigned={() => setShowAssignDriverForm(false)}
                  onCancel={() => setShowAssignDriverForm(false)}
                />
              )}
              <InnerCard
                onClick={() => setShowMakePublicForm(!showMakePublicForm)}
              >
                <h3>Make it Public</h3>
              </InnerCard>
              {showMakePublicForm && (
                <>
                  <GreenP>
                    <strong>Note:</strong> When You make this job Public, it
                    will no longer be treated as a Personal Request to the
                    Driver and will instead become available to all of our
                    approved Drivers.
                  </GreenP>
                  {makePublicError && <RedP>{makePublicError}</RedP>}
                  {loadingPublic ? (
                    <Spinner />
                  ) : (
                    <SpreadButtonContainer>
                      <Button
                        buttonType="inverted"
                        onClick={() => {
                          setShowMakePublicForm(false);
                          setMakePublicError("");
                        }}
                      >
                        Go Back
                      </Button>
                      <Button onClick={makePublicHandler}>
                        Make it Public
                      </Button>
                    </SpreadButtonContainer>
                  )}
                </>
              )}
              <InnerCard onClick={() => setShowCancelForm(!showCancelForm)}>
                <h3>Cancel Job</h3>
              </InnerCard>
              {showCancelForm && (
                <>
                  <AmberHeader>Cancel this job?</AmberHeader>
                  {cancelJobError ? (
                    <RedP>{cancelJobError}</RedP>
                  ) : (
                    <>
                      <FaExclamationTriangle size={24} color={colors.primary} />
                      <GreenP>
                        Please ensure You have a valid reason to cancel this
                        job, rather than passing it to a specific Driver or
                        making it publicly available to all approved Drivers.
                      </GreenP>
                    </>
                  )}

                  <FormInput
                    label="Please type the reason..."
                    onChange={reasonChangeHandler}
                    id="reasonOfReject"
                    name="reasonOfReject"
                    type="text"
                    value={reasonOfReject}
                    required
                  />
                  <SpreadButtonContainer>
                    <Button
                      buttonType="inverted"
                      onClick={() => {
                        setShowCancelForm(false);
                      }}
                    >
                      Go Back
                    </Button>
                    <Button onClick={cancelJobHandler}>Cancel Job</Button>
                  </SpreadButtonContainer>
                </>
              )}
            </>
          </>
        )}
    </Card>
  );
};

export default BookingDetails;
