import axios, { AxiosError } from "axios";

import { GetPriceQuoteType } from "../redux/user/user.types";
import { CreateBookingFromQuoteType } from "../redux/bookings/bookings.types";
import { auth } from "./config";

export const getPriceQuote = async (quoteRequest: GetPriceQuoteType) => {
  if (auth !== null && auth.currentUser !== null) {
    try {
      // Get the user's ID token from Firebase Authentication
      const idToken = await auth.currentUser.getIdToken();
      // const quoteUri = "http://localhost:5001/rufus-taxi/europe-west2/requestQuote" //local tesing
      const quoteUri = "https://requestquote-tvhiteyzsa-nw.a.run.app";
      const { data } = await axios.post(quoteUri, quoteRequest, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      return data;
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error processing the quote. Please, try again later");
      }
    }
  } else if (quoteRequest.tempQuoteId && quoteRequest.tempQuoteId !== "") {
    try {
      // Request quote for not logged in users
      // const quoteUri =
        // "http://localhost:5001/rufus-taxi/europe-west2/requestPublicQuote"; //local tesing
      const quoteUri = "https://requestpublicquote-tvhiteyzsa-nw.a.run.app";
      const { data } = await axios.post(quoteUri, quoteRequest);
      return data;
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error processing the quote. Please, try again later");
      }
    }
  } else {
    throw new Error("Authorization missing!");
  }
};

//Migrate temp quote to user's account (recent quote), if user is logged in first time and has a temp quote
// used directly in App.tsx as it's used to set the state of the app
// export const migrateTemporaryQuote = async () => {
//   const tempQuoteId = localStorage.getItem("tempQuoteId");
//   if (auth !== null && auth.currentUser !== null && tempQuoteId) {
//     try {
//       // Get the user's ID token from Firebase Authentication
//       const idToken = await auth.currentUser.getIdToken();
//       const migrateUri =
//         "http://localhost:5001/rufus-taxi/europe-west2/migrateQuote"; //local tesing
//       // const migrateUri = "https://canceljob-tvhiteyzsa-nw.a.run.app";
//       await axios.post(
//         migrateUri,
//         { tempQuoteId },
//         {
//           headers: {
//             Authorization: `Bearer ${idToken}`,
//           },
//         }
//       );
//     } catch (error) {
//       console.error("Failed to migrate quote:", error);
//     } finally {
//       localStorage.removeItem("tempQuoteId"); // Cleanup in all cases
      
//     }
//   }
// };

//Do it in a cloud!
export const createBookingFromRecentQuote = async (
  newBookingRequest: CreateBookingFromQuoteType
) => {
  if (auth !== null && auth.currentUser !== null) {
    try {
      // Get the user's ID token from Firebase Authentication
      const idToken = await auth.currentUser.getIdToken();
      // const quoteUri =
      //   "http://localhost:5001/rufus-taxi/europe-west2/createBooking"; //local tesing
      const quoteUri = "https://createbooking-tvhiteyzsa-nw.a.run.app";
      await axios.post(quoteUri, newBookingRequest, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error creating new booking! Please, try again later.");
      }
    }
  } else {
    throw new Error("Authorization missing!");
  }
};

//Request GepoPoint in a cloud
export const requestGeoPoint = async (address: string) => {
  if (auth !== null && auth.currentUser !== null) {
    try {
      // Get the user's ID token from Firebase Authentication
      const idToken = await auth.currentUser.getIdToken();
      // const geoPointUri =
      //   "http://localhost:5001/rufus-taxi/europe-west2/getGeoPoint"; //local tesing
      const geoPointUri = "https://getgeopoint-tvhiteyzsa-nw.a.run.app";
      const { data } = await axios.post(
        geoPointUri,
        { address },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return data;
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error requesting GeoPoint! Please, try again later.");
      }
    }
  } else {
    throw new Error("Authorization missing!");
  }
};

//customer cancels booking via cloud endpoint, as firestore rules don't allow editing
export const cancelBookingRequest = async (bookingId: string) => {
  if (auth !== null && auth.currentUser !== null) {
    try {
      // Get the user's ID token from Firebase Authentication
      const idToken = await auth.currentUser.getIdToken();
      // const cancelingUri =
      //   "http://localhost:5001/rufus-taxi/europe-west2/cancelBooking"; //local tesing
      const cancelingUri = "https://cancelbooking-tvhiteyzsa-nw.a.run.app";
      const { data } = await axios.post(
        cancelingUri,
        { bookingId },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return data;
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error cancelling job.");
      }
    }
  } else {
    throw new Error("Authorization missing!");
  }
};

//Driver turns Private job into Public via cloud endpoint, as firestore rules don't allow editing
export const makeJobPublic = async (bookingId: string) => {
  if (auth !== null && auth.currentUser !== null) {
    try {
      // Get the user's ID token from Firebase Authentication
      const idToken = await auth.currentUser.getIdToken();
      // const makePublicUri =
      //   "http://localhost:5001/rufus-taxi/europe-west2/makePrivateJobPublic"; //local tesing
      const makePublicUri =
        "https://makeprivatejobpublic-tvhiteyzsa-nw.a.run.app";
      const { data } = await axios.post(
        makePublicUri,
        { bookingId },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return data;
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error making job Public.");
      }
    }
  } else {
    throw new Error("Authorization missing!");
  }
};

//Driver or Admin adds a booking note
export const addBookingNote = async (jobId: string, note: string) => {
  if (auth !== null && auth.currentUser !== null) {
    try {
      // Get the user's ID token from Firebase Authentication
      const idToken = await auth.currentUser.getIdToken();
      // const addNoteUri =
      //   "http://localhost:5001/rufus-taxi/europe-west2/addNoteToJob"; //local tesing
      const addNoteUri = "https://addnotetojob-tvhiteyzsa-nw.a.run.app";
      const { data } = await axios.post(
        addNoteUri,
        { jobId, note },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return data;
    } catch (error) {
      if (error instanceof AxiosError && !!error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Error adding a note to the job.");
      }
    }
  } else {
    throw new Error("Authorization missing!");
  }
};
