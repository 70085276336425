import { FC } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

import Card from "../../components/UI/card/card.component";
import {
  BackButtonContainer,
  GreenHeader,
  GreenP,
  SpreadApartArea,
  TextDivider,
} from "../../global.styles";
import {
  BookingType,
  QuoteRequestType,
} from "../../redux/bookings/bookings.types";
import QuoteDetails from "../../components/bookings/quote-details";
import Button from "../../components/UI/button/button.component";

// type BookingFormProps = {
//   guoteRequest: QuoteRequestType;
//   quote: BookingType;
// };

const BookingForm: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (
    location.state === null ||
    !location.state.quoteRequest ||
    !location.state.quote
  )
    return <Navigate to="/" replace />;

  const quoteRequest = location.state.quoteRequest as QuoteRequestType;
  const quote = location.state.quote as BookingType;

  return (
    <Card>
      <BackButtonContainer>
        <Link to="/" state={{ quoteRequest }}>
          <h3>Edit Booking</h3>
        </Link>
      </BackButtonContainer>
      <QuoteDetails quote={quote} />
      <TextDivider>
        <GreenP> CONFIRM </GreenP>
      </TextDivider>
      <GreenHeader>Are details above correct?</GreenHeader>
      <SpreadApartArea>
        <Link to="/" state={{ quoteRequest }}>
          <Button buttonType="inverted">No, Edit Booking</Button>
        </Link>
        <Button
          onClick={() => {
            navigate("/login");
          }}
        >
          Yes, Log Me In
        </Button>
      </SpreadApartArea>
    </Card>
  );
};

export default BookingForm;
