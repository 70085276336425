import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, onSnapshot } from "firebase/firestore";

import { firestore } from "../../firebase/config";
import Card from "../UI/card/card.component";
import Spinner from "../UI/spinner/spinner.component";
import ErrorMessage from "../UI/error-message/error-message.component";
import {
  AmberP,
  ButtonColumnContainer,
  ButtonContainer,
  GreenHeader,
  GreenP,
  RedHeader,
  RedP,
  SpreadApartArea,
  TextDivider,
} from "../../global.styles";
import InnerCard from "../UI/inner-card/inner-card.component";
import ApproveVehiclePaperwork from "./forms/approve-vehicle-paperwork.component";
import SetMakeModelForm from "./forms/set-make-model.component";
import Button from "../UI/button/button.component";
import Modal from "../UI/modal/modal.component";
import { CurrentUserType } from "../../redux/user/user.types";
import { PHVehicleType } from "../../redux/vehicles/vehicles.types";
import {
  changeVehicleStatus,
  removeVehicleApprovalWaiting,
} from "../../firebase/admin";

const required = <RedP>Not Provided</RedP>;
const requiredAmber = <AmberP>Required</AmberP>;
const pending = <AmberP>Waitng for approval...</AmberP>;
// const optional = <AmberP>Optional</AmberP>;
const approved = <GreenP>Approved</GreenP>;

const VehicleDetails = () => {
  const { userId, numberPlate } = useParams();
  const navigate = useNavigate();

  //Driver
  const [selectedUserLoading, setSelectedUserLoading] = useState(false);
  const [selectedUserError, setSelectedUserError] = useState("");
  const [selectedUser, setSelectedUser] = useState<CurrentUserType | null>(
    null
  );
  //Car
  const [selectedVehicleLoading, setSelectedVehicleLoading] = useState(false);
  const [selectedVehicleError, setSelectedVehicleError] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState<PHVehicleType | null>(
    null
  );

  //modal to confirm vehicle activation or suspension (approve or ban)
  const [openModal, setOpenModal] = useState(false);
  const [approve, setApprove] = useState<boolean | null>(null);
  //modal to reset waiting for approval status
  const [openResetWaitModal, setOpenResetWaitModal] = useState(false);

  //show color/make/model setting form
  const [showCarDetailsForm, setShowCarDetailsForm] = useState(false);

  //show paperwork approval forms
  const [showPHVehicleLicenceForm, setShowPHVehicleLicenceForm] =
    useState(false);
  const [showLogBookForm, setShowLogBookForm] = useState(false);
  const [showMOTCertificateForm, setShowMOTCertificateForm] = useState(false);
  const [showInsuranceCertificateForm, setShowInsuranceCertificateForm] =
    useState(false);
  const [showHireContractForm, setShowHireContractForm] = useState(false);

  //set onSnapshot (real time updates connection) for the Driver
  useEffect(() => {
    setSelectedUserLoading(true);
    if (userId) {
      const unsubscribe = onSnapshot(
        doc(firestore, "users", userId),
        { includeMetadataChanges: true },
        (userDoc) => {
          if (userDoc.exists()) {
            // Handle document existence
            if (!userDoc.metadata.hasPendingWrites) {
              // Data is up-to-date
              const updatedUser = {
                id: userDoc.id,
                ...userDoc.data(),
                createdAt: userDoc.data()!.createdAt.toMillis(),
                lastUpdate: userDoc.data()!.lastUpdate.toMillis(),
              };
              setSelectedUser(updatedUser as CurrentUserType);
              setSelectedUserLoading(false);
            } else if (
              userDoc.metadata.hasPendingWrites &&
              !userDoc.metadata.fromCache
            ) {
              // Data is being updated, show a loading indicator
              setSelectedUserLoading(true); // Loading state
              // Clear existing data
              // ... (Display a loading indicator)
            } else if (
              userDoc.metadata.hasPendingWrites &&
              userDoc.metadata.fromCache
            ) {
              // Data is outdated, refresh from the server
              setSelectedUserLoading(true); // Loading state
              // Trigger a refresh from the server
              getDoc(doc(firestore, "users", userId))
                .then((docSnap) => {
                  if (docSnap.exists()) {
                    const updatedUser = {
                      id: docSnap.id,
                      ...docSnap.data(),
                      createdAt: docSnap.data()!.createdAt.toMillis(),
                      lastUpdate: docSnap.data()!.lastUpdate.toMillis(),
                    };
                    setSelectedUser(updatedUser as CurrentUserType);
                    setSelectedUserLoading(false);
                  } else {
                    // Handle document not found
                    setSelectedUserError("User not found");
                    setSelectedUserLoading(false);
                    // ... (Display an error message or redirect)
                  }
                })
                .catch((error) => {
                  if (error instanceof Error) {
                    setSelectedUserError(error.message);
                    setSelectedUserLoading(false);
                  } else {
                    setSelectedUserError("Error fetching user");
                    setSelectedUserLoading(false);
                  }
                });
            }
          } else {
            // Handle document not found
            setSelectedUserError("User not found");
            setSelectedUserLoading(false);
            // ... (Display an error message or redirect)
          }
        },
        (error) => {
          if (error instanceof Error) {
            setSelectedUserError(error.message);
            setSelectedUserLoading(false);
          } else {
            setSelectedUserError("Error fetching a user");
            setSelectedUserLoading(false);
          }
        }
      );
      return () => unsubscribe();
    } else {
      navigate("/boss");
    }
  }, [navigate, userId]);

  //set onSnapshot (real time updates connection) for this car
  useEffect(() => {
    setSelectedVehicleLoading(true);
    if (userId && numberPlate) {
      const unsubscribe = onSnapshot(
        doc(firestore, "users", userId, "PHVehicles", numberPlate),
        { includeMetadataChanges: true },
        (vehicleDoc) => {
          if (vehicleDoc.exists()) {
            // Handle document existence
            if (!vehicleDoc.metadata.hasPendingWrites) {
              // Data is up-to-date
              const updatedVehicle = {
                ...vehicleDoc.data(),
                createdAt: vehicleDoc.data()!.createdAt.toMillis(),
                lastUpdate: vehicleDoc.data()!.lastUpdate.toMillis(),
              };
              setSelectedVehicle(updatedVehicle as PHVehicleType);
              setSelectedVehicleLoading(false);
            } else if (
              vehicleDoc.metadata.hasPendingWrites &&
              !vehicleDoc.metadata.fromCache
            ) {
              // Data is being updated, show a loading indicator
              setSelectedVehicleLoading(true); // Loading state
              // Clear existing data
              // ... (Display a loading indicator)
            } else if (
              vehicleDoc.metadata.hasPendingWrites &&
              vehicleDoc.metadata.fromCache
            ) {
              // Data is outdated, refresh from the server
              setSelectedVehicleLoading(true); // Loading state
              // Trigger a refresh from the server
              getDoc(doc(firestore, "users", userId, "PHVehicles", numberPlate))
                .then((docSnap) => {
                  if (docSnap.exists()) {
                    const updatedVehicle = {
                      ...docSnap.data(),
                      createdAt: docSnap.data()!.createdAt.toMillis(),
                      lastUpdate: docSnap.data()!.lastUpdate.toMillis(),
                    };
                    setSelectedVehicle(updatedVehicle as PHVehicleType);
                    setSelectedVehicleLoading(false);
                  } else {
                    // Handle document not found
                    setSelectedVehicleError("Vehicle not found");
                    setSelectedVehicleLoading(false);
                    // ... (Display an error message or redirect)
                  }
                })
                .catch((error) => {
                  if (error instanceof Error) {
                    setSelectedVehicleError(error.message);
                    setSelectedVehicleLoading(false);
                  } else {
                    setSelectedVehicleError("Error fetching vehicle");
                    setSelectedVehicleLoading(false);
                  }
                });
            }
          } else {
            // Handle document not found
            setSelectedVehicleError("Vehicle not found");
            setSelectedVehicleLoading(false);
            // ... (Display an error message or redirect)
          }
        },
        (error) => {
          if (error instanceof Error) {
            setSelectedVehicleError(error.message);
            setSelectedVehicleLoading(false);
          } else {
            setSelectedVehicleError("Error fetching a vehicle");
            setSelectedVehicleLoading(false);
          }
        }
      );
      return () => unsubscribe();
    } else {
      navigate("/boss");
    }
  }, [navigate, numberPlate, userId]);

  //reset "waitng for approval" boolean
  const resetWaitingHandler = () => {
    setOpenResetWaitModal(true);
  };
  const resetWaitingConfirm = async () => {
    if (userId && numberPlate) {
      try {
        await removeVehicleApprovalWaiting(userId, numberPlate);
      } catch (error) {
        if (error instanceof Error) {
          setSelectedVehicleError(error.message);
        } else {
          setSelectedVehicleError(
            "Error changing waiting for approval status for this vehicle"
          );
        }
      }
    }
  };
  //activate of suspend the vehicle
  const activationHandler = (activate: boolean) => {
    setApprove(activate);
    setOpenModal(true);
  };
  const activationConfirm = async () => {
    if (userId && numberPlate && approve !== null) {
      try {
        await changeVehicleStatus(userId, numberPlate, approve);
      } catch (error) {
        if (error instanceof Error) {
          setSelectedVehicleError(error.message);
        } else {
          setSelectedVehicleError("Error changing status for this vehicle");
        }
      }
    }
  };

  return (
    <>
      {selectedUserLoading && <Spinner />}
      {selectedUserError && (
        <ErrorMessage slice="selectedUser">{selectedUserError}</ErrorMessage>
      )}
      {selectedUser && selectedUser.driversData && (
        <Card>
          {openModal && approve !== null && (
            <Modal
              title={approve ? "Activate" : "Suspend"}
              body={`${
                approve ? "Activate" : "Suspend"
              } this vehicle? Are you sure?`}
              confirmButtonText={approve ? "Activate" : "Suspend"}
              setOpenModal={setOpenModal}
              onModalConfirm={activationConfirm}
            />
          )}
          {openResetWaitModal && (
            <Modal
              title="Reset Waiting Status"
              body="Is all paperwork and data for this vehicle reviewed? Are you sure?"
              confirmButtonText="Reset"
              setOpenModal={setOpenResetWaitModal}
              onModalConfirm={resetWaitingConfirm}
            />
          )}
          <TextDivider>
            <GreenP>DRIVERS DATA</GreenP>
          </TextDivider>

          <SpreadApartArea>
            <GreenP>Name:</GreenP>
            <p>
              <strong>{selectedUser.name}</strong>
            </p>
          </SpreadApartArea>
          <SpreadApartArea>
            <GreenP>Licence:</GreenP>
            <p>
              <strong>{selectedUser.driversData.licensingAuthority}</strong>
            </p>
          </SpreadApartArea>
          <TextDivider>
            <GreenP>VEHICLE - {numberPlate}</GreenP>
          </TextDivider>
          {selectedVehicleLoading && <Spinner />}
          {selectedVehicleError && (
            <ErrorMessage slice="selectedVehicle">
              {selectedVehicleError}
            </ErrorMessage>
          )}
          {selectedVehicle && (
            <>
              <InnerCard
                onClick={() => {
                  setShowCarDetailsForm(!showCarDetailsForm);
                }}
              >
                <h3>Colour/Make/Model</h3>
                {selectedVehicle.make &&
                selectedVehicle.model &&
                selectedVehicle.color &&
                selectedVehicle.licencePlate ? (
                  <div>
                    <GreenP>{selectedVehicle.color}</GreenP>
                    <GreenP>{selectedVehicle.make}</GreenP>
                    <GreenP>{selectedVehicle.model}</GreenP>
                    <GreenP>{selectedVehicle.licencePlate}</GreenP>
                  </div>
                ) : (
                  required
                )}
              </InnerCard>
              {showCarDetailsForm && userId && (
                <SetMakeModelForm
                  userId={userId}
                  selectedVehicle={selectedVehicle}
                  onCancelSet={() => setShowCarDetailsForm(false)}
                  onError={setSelectedVehicleError}
                />
              )}
              <InnerCard
                onClick={() =>
                  setShowPHVehicleLicenceForm(!showPHVehicleLicenceForm)
                }
              >
                <h3>Vehicle Licence</h3>
                {selectedVehicle.PHVehicleLicence &&
                selectedVehicle.PHVehicleLicence.pendingUrl ? (
                  pending
                ) : selectedVehicle.PHVehicleLicence &&
                  selectedVehicle.PHVehicleLicence.approved ? (
                  approved
                ) : selectedVehicle.PHVehicleLicence &&
                  selectedVehicle.PHVehicleLicence.reasonOfDecline ? (
                  <RedP>
                    {selectedVehicle.PHVehicleLicence.reasonOfDecline}
                  </RedP>
                ) : (
                  required
                )}
              </InnerCard>
              {showPHVehicleLicenceForm &&
                (selectedVehicle.PHVehicleLicence?.pendingUrl ||
                  selectedVehicle.PHVehicleLicence?.downloadUrl ||
                  selectedVehicle.PHVehicleLicence?.expiringUrl) && (
                  <ApproveVehiclePaperwork
                    selectedUser={selectedUser}
                    selectedVehicle={selectedVehicle}
                    docTitle="PHVehicleLicence"
                    onDocumentReviewed={() =>
                      setShowPHVehicleLicenceForm(false)
                    }
                    onError={setSelectedVehicleError}
                  />
                )}

              <InnerCard
                onClick={() => {
                  setShowLogBookForm(!showLogBookForm);
                }}
              >
                <h3>Log Book - 2nd page</h3>
                {selectedVehicle.logBook &&
                selectedVehicle.logBook.pendingUrl ? (
                  pending
                ) : selectedVehicle.logBook &&
                  selectedVehicle.logBook.approved ? (
                  approved
                ) : selectedVehicle.logBook &&
                  selectedVehicle.logBook.reasonOfDecline ? (
                  <RedP>{selectedVehicle.logBook.reasonOfDecline}</RedP>
                ) : (
                  required
                )}
              </InnerCard>
              {showLogBookForm &&
                (selectedVehicle.logBook?.pendingUrl ||
                  selectedVehicle.logBook?.downloadUrl ||
                  selectedVehicle.logBook?.expiringUrl) && (
                  <ApproveVehiclePaperwork
                    selectedUser={selectedUser}
                    selectedVehicle={selectedVehicle}
                    docTitle="logBook"
                    onDocumentReviewed={() => setShowLogBookForm(false)}
                    onError={setSelectedVehicleError}
                  />
                )}

              <InnerCard
                onClick={() => {
                  setShowInsuranceCertificateForm(
                    !showInsuranceCertificateForm
                  );
                }}
              >
                <h3>Insurance Certificate</h3>
                {selectedVehicle.insuranceCertificate &&
                selectedVehicle.insuranceCertificate.pendingUrl ? (
                  pending
                ) : selectedVehicle.insuranceCertificate &&
                  selectedVehicle.insuranceCertificate.approved ? (
                  approved
                ) : selectedVehicle.insuranceCertificate &&
                  selectedVehicle.insuranceCertificate.reasonOfDecline ? (
                  <RedP>
                    {selectedVehicle.insuranceCertificate.reasonOfDecline}
                  </RedP>
                ) : (
                  required
                )}
              </InnerCard>
              {showInsuranceCertificateForm &&
                (selectedVehicle.insuranceCertificate?.pendingUrl ||
                  selectedVehicle.insuranceCertificate?.downloadUrl ||
                  selectedVehicle.insuranceCertificate?.expiringUrl) && (
                  <ApproveVehiclePaperwork
                    selectedUser={selectedUser}
                    selectedVehicle={selectedVehicle}
                    docTitle="insuranceCertificate"
                    onDocumentReviewed={() =>
                      setShowInsuranceCertificateForm(false)
                    }
                    onError={setSelectedVehicleError}
                  />
                )}

              <InnerCard
                onClick={() => {
                  setShowMOTCertificateForm(!showMOTCertificateForm);
                }}
              >
                <div>
                  <h3>MOT Certificate</h3>
                  <AmberP>If the car is over 3 years old</AmberP>
                </div>
                {selectedVehicle.MOTCertificate &&
                selectedVehicle.MOTCertificate.pendingUrl ? (
                  pending
                ) : selectedVehicle.MOTCertificate &&
                  selectedVehicle.MOTCertificate.approved ? (
                  approved
                ) : selectedVehicle.MOTCertificate &&
                  selectedVehicle.MOTCertificate.reasonOfDecline ? (
                  <RedP>{selectedVehicle.MOTCertificate.reasonOfDecline}</RedP>
                ) : (
                  requiredAmber
                )}
              </InnerCard>
              {showMOTCertificateForm &&
                (selectedVehicle.MOTCertificate?.pendingUrl ||
                  selectedVehicle.MOTCertificate?.downloadUrl ||
                  selectedVehicle.MOTCertificate?.expiringUrl) && (
                  <ApproveVehiclePaperwork
                    selectedUser={selectedUser}
                    selectedVehicle={selectedVehicle}
                    docTitle="MOTCertificate"
                    onDocumentReviewed={() => setShowMOTCertificateForm(false)}
                    onError={setSelectedVehicleError}
                  />
                )}

              <InnerCard
                onClick={() => {
                  setShowHireContractForm(!showHireContractForm);
                }}
              >
                <div>
                  <h3>Hire Contract</h3>
                  <AmberP>If the car is rented</AmberP>
                </div>
                {selectedVehicle.hireContract &&
                selectedVehicle.hireContract.pendingUrl ? (
                  pending
                ) : selectedVehicle.hireContract &&
                  selectedVehicle.hireContract.approved ? (
                  approved
                ) : selectedVehicle.hireContract &&
                  selectedVehicle.hireContract.reasonOfDecline ? (
                  <RedP>{selectedVehicle.hireContract.reasonOfDecline}</RedP>
                ) : (
                  requiredAmber
                )}
              </InnerCard>
              {showHireContractForm &&
                (selectedVehicle.hireContract?.pendingUrl ||
                  selectedVehicle.hireContract?.downloadUrl ||
                  selectedVehicle.hireContract?.expiringUrl) && (
                  <ApproveVehiclePaperwork
                    selectedUser={selectedUser}
                    selectedVehicle={selectedVehicle}
                    docTitle="hireContract"
                    onDocumentReviewed={() => setShowHireContractForm(false)}
                    onError={setSelectedVehicleError}
                  />
                )}
            </>
          )}
          {selectedVehicle?.waitingApproval && (
            <>
              <TextDivider>
                <GreenP>WAITING FOR APPROVAL</GreenP>
              </TextDivider>
              <ButtonColumnContainer>
                <Button onClick={resetWaitingHandler}>
                  Remove From Waiting...
                </Button>
              </ButtonColumnContainer>
            </>
          )}
          <TextDivider>
            <GreenP>STATUS</GreenP>
          </TextDivider>
          <ButtonColumnContainer>
            {selectedVehicle?.approved ? (
              <GreenHeader>Active</GreenHeader>
            ) : (
              <RedHeader>NOT Active</RedHeader>
            )}
          </ButtonColumnContainer>
          <ButtonContainer>
            <Button
              buttonType="warning"
              onClick={activationHandler.bind(null, false)}
            >
              Suspend
            </Button>
            <Button
              buttonType="green"
              onClick={activationHandler.bind(null, true)}
            >
              Activate
            </Button>
          </ButtonContainer>
        </Card>
      )}
    </>
  );
};

export default VehicleDetails;
